import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Shield } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';
import LanguageSwitcher from '../utils/LanguageSwitcher';

export default function Navbar() {
  const { t } = useLanguage();
  const [onlineDropdownOpen, setOnlineDropdownOpen] = useState(false);
  const [paymentDropdownOpen, setPaymentDropdownOpen] = useState(false);
  const [usCompanyDropdownOpen, setUsCompanyDropdownOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [overseaCompanyDropdownOpen, setOverseaCompanyDropdownOpen] = useState(false);
  const [otherDropdownOpen, setOtherDropdownOpen] = useState(false);
  const timeoutRef = useRef<number | undefined>(undefined);
  const timeoutRef2 = useRef<number | undefined>(undefined);
  const timeoutRef3 = useRef<number | undefined>(undefined);
  const timeoutRef4 = useRef<number | undefined>(undefined);
  const timeoutRef5 = useRef<number | undefined>(undefined);
  const timeoutRef6 = useRef<number | undefined>(undefined);

  return (
    <nav className="bg-blue-900 text-white fixed w-full z-50">
      <div className="container mx-auto px-4 sm:px-4 lg:px-8">
        <div className="flex gap-4 justify-between h-24 items-center">
          <Link to="/" className="flex items-center">
            <Shield className="h-8 w-8 text-red-500 " />
            <span className="ml-2 text-xl font-bold ">Oversea Business Services</span>
          </Link>
          <div className="hidden md:flex items-center space-x-12">
            <div
              className="relative hover:text-red-400"
              onMouseEnter={() => {
                clearTimeout(timeoutRef3.current); // Clear any pending timeout
                setUsCompanyDropdownOpen(true); // Open dropdown immediately
              }}
              onMouseLeave={() => {
                timeoutRef3.current = setTimeout(() => {
                  setUsCompanyDropdownOpen(false); // Close dropdown after delay
                }, 100); // Adjust delay time (300ms in this case)
              }}
            >
              <Link to="/company" className="hover:text-red-400">{t('nav.Company')}</Link>
              {usCompanyDropdownOpen && (
                <div className="absolute flex-col bg-blue-800 text-white items-center w-40 mt-2 shadow-lg -left-8">
                  <Link to="/company" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400"
                    onClick={() => {
                      setTimeout(() => {
                        const element = document.getElementById("us-company-registration");
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth", block: "start" });
                        }
                      }, 100); // Delay to ensure the new page has loaded
                    }}>
                    {t('nav.Company.us.registration')}
                  </Link>
                  <Link to="/company" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400"
                    onClick={() => {
                      setTimeout(() => {
                        const element = document.getElementById("us-company-maintenance");
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth", block: "start" });
                        }
                      }, 100); // Delay to ensure the new page has loaded
                    }}>
                    {t('nav.Company.us.maintenance')}
                  </Link>
                </div>
              )}
            </div>

            {/* Oversea Company */}
            <div
              className="relative hover:text-red-400"
              onMouseEnter={() => {
                clearTimeout(timeoutRef6.current); // Clear any pending timeout
                setOverseaCompanyDropdownOpen(true); // Open dropdown immediately
              }}
              onMouseLeave={() => {
                timeoutRef6.current = setTimeout(() => {
                  setOverseaCompanyDropdownOpen(false); // Close dropdown after delay
                }, 100); // Adjust delay time (300ms in this case)
              }}
            >
            {t('nav.oversea')}
              {overseaCompanyDropdownOpen && (
                <div className="absolute flex-col bg-blue-800 text-white items-center w-40 mt-2 shadow-lg -left-8">
                  <Link to="/company" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400"
                    onClick={() => {
                      setTimeout(() => {
                        const element = document.getElementById("other-company-registration");
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth", block: "start" });
                        }
                      }, 100); // Delay to ensure the new page has loaded
                    }}>
                    {t('nav.Company.out.registration')}
                  </Link>
                  <Link to="/offshore" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400">{t('nav.offshore')}</Link>
                </div>
              )}
            </div>


            {/* payment */}
            <div
              className="relative hover:text-red-400"
              onMouseEnter={() => {
                clearTimeout(timeoutRef4.current); // Clear any pending timeout
                setPaymentDropdownOpen(true); // Open dropdown immediately
              }}
              onMouseLeave={() => {
                timeoutRef4.current = setTimeout(() => {
                  setPaymentDropdownOpen(false); // Close dropdown after delay
                }, 100); // Adjust delay time (300ms in this case)
              }}
            >
              {t('nav.payment')}
              {paymentDropdownOpen && (
                <div className="absolute flex-col bg-blue-800 text-white items-center w-32 mt-2 shadow-lg -left-4">
                  <Link to="/banking" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400">{t('nav.payment.banking')}</Link>
                  <Link to="/ecommerce/payment" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400">{t('nav.online.payment')}</Link>
                  <Link to="/otc" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400">{t('nav.otc')}</Link>
                  {/* <Link to="/otc" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400"
                    onClick={() => {
                      setTimeout(() => {
                        const element = document.getElementById("usdt");
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth", block: "start" });
                        }
                      }, 100); // Delay to ensure the new page has loaded
                    }}
                  >{t('nav.usdt')}</Link> */}
                </div>
              )}
            </div>

            {/* online accounts */}
            <div
              className="relative hover:text-red-400"
              onMouseEnter={() => {
                clearTimeout(timeoutRef.current); // Clear any pending timeout
                setOnlineDropdownOpen(true); // Open dropdown immediately
              }}
              onMouseLeave={() => {
                timeoutRef.current = setTimeout(() => {
                  setOnlineDropdownOpen(false); // Close dropdown after delay
                }, 100); // Adjust delay time (300ms in this case)
              }}
            >
              {t('nav.online')}
              {onlineDropdownOpen && (
                <div className="absolute flex-col bg-blue-800 text-white items-center w-32 mt-2 shadow-lg -left-4">
                  <Link to="/ecommerce/account" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400">{t('nav.online.account')}</Link>
                  <Link to="/ecommerce/financial" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400">{t('nav.online.financial')}</Link>
                </div>
              )}
            </div>

            {/* settlement */}
            {/* <div
              className="relative hover:text-red-400 max-w-48"
              onMouseEnter={() => {
                clearTimeout(timeoutRef2.current); // Clear any pending timeout
                setServiceDropdownOpen(true); // Open dropdown immediately
              }}
              onMouseLeave={() => {
                timeoutRef2.current = setTimeout(() => {
                  setServiceDropdownOpen(false); // Close dropdown after delay
                }, 100); // Adjust delay time (300ms in this case)
              }}
            >
              {t('nav.settlement')}
              {serviceDropdownOpen && (
                <div className="absolute flex-col bg-blue-800 text-white items-center w-32 mt-2 shadow-lg -left-4">
                  <Link to="/otc" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400">{t('nav.otc')}</Link>
                  <Link to="/otc" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400"
                    onClick={() => {
                      setTimeout(() => {
                        const element = document.getElementById("usdt");
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth", block: "start" });
                        }
                      }, 100); // Delay to ensure the new page has loaded
                    }}
                  >{t('nav.usdt')}</Link>
                </div>
              )}
            </div> */}

            {/* other services */}
            <div
              className="relative hover:text-red-400"
              onMouseEnter={() => {
                clearTimeout(timeoutRef5.current); // Clear any pending timeout
                setOtherDropdownOpen(true); // Open dropdown immediately
              }}
              onMouseLeave={() => {
                timeoutRef5.current = setTimeout(() => {
                  setOtherDropdownOpen(false); // Close dropdown after delay
                }, 100); // Adjust delay time (300ms in this case)
              }}
            >
              {t('nav.other')}
              {otherDropdownOpen && (
                <div className="absolute flex-col bg-blue-800 text-white items-center w-32 mt-2 shadow-lg -left-4">
                  <Link to="/insurance" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400">
                    {t('nav.insurance')}
                  </Link>
                  <Link to="/vpn" className="block text-center px-2 py-2 hover:bg-blue-200 hover:text-red-400">
                    {t('nav.vpn')}
                  </Link>
                </div>
              )}
            </div>
            {/* contact us */}
            <Link to="/contact" className="hover:text-red-400">{t('nav.contact')}</Link>
          
            <LanguageSwitcher />
          </div>
        </div>  
      </div>
    </nav>
  );
}