import { useLocation, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from "react";



export default function PaymentSuccess() {
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get("session_id");
    const [sessionData, setSessionData] = useState(null);

    const env = import.meta.env;
    const serverUrl = env.VITE_SERVER_URL;
    useEffect(() => {
        const fetchSession = async () => {
            try {
                const response = await fetch(`${serverUrl}/retrieve-session?session_id=${sessionId}`);
                const data = await response.json();
                setSessionData(data);
            } catch (error) {
                console.error("Error fetching session:", error);
            }
        };

        if (sessionId) {
            fetchSession();
        }
    }, [sessionId]);

    return (
        <div className="min-h-screen bg-gray-50  ">
            <div className="flex flex-col items-center justify-center  pt-32">
                <h1 className="text-3xl font-bold text-center p-8">Payment Successful!</h1>
                {sessionData && (
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl">
                        <p className="mb-4"><strong>Product:</strong> {sessionData.metadata.product}</p>
                        <p className="mb-4"><strong>Service:</strong> {sessionData.metadata.service}</p>
                        <p className="mb-4"><strong>Price:</strong> ${sessionData.metadata.price}</p>
                        <p className="mb-4"><strong>Billing Name:</strong> {sessionData.metadata.billing_name}</p>
                        <p className="mb-4"><strong>Email:</strong> {sessionData.metadata.billing_email}</p>
                        <p className="mb-4"><strong>Phone:</strong> {sessionData.metadata.billing_phone}</p>
                    </div>
                )}
                <p className="mt-4">Our team will contact you shortly.</p>
            </div>
        </div>

    )
}