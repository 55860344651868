import { Link } from 'react-router-dom';
import { Phone } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';

export default function ContactCTA() {
  const { t } = useLanguage();

  return (
    <div className="py-16 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold mb-4">{t('contact.title')}</h2>
        <p className="text-xl text-gray-600 mb-8">{t('contact.subtitle')}</p>
        <Link to="/contact" className="bg-red-600 w-48 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-red-700 transition duration-300 flex items-center mx-auto"
        onClick={() => {
          setTimeout(() => {
            const element = document.getElementById("contact-form");
            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
          }, 100); // Delay to ensure the new page has loaded
        }}>
          <Phone className="mr-2 h-5 w-5" />
          {t('contact.cta')}
        </Link>
      </div>
    </div>
  );
}