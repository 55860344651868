import { Phone, Mail, MessageCircle, Clock, Building2 } from 'lucide-react';
import whatsappImage from '../assets/whatsapp_overseabiz.jpg';
import wechatImage from '../assets/wechat_overseabiz.jpg';
import telegramImage from '../assets/telegram_overseabiz.jpg';
import React, { useState } from 'react';

export default function ContactPage() {
  const contactMethods = [
    {
      icon: Mail,
      title: 'Telegram',
      value: 'OverseaBiz',
      description: '7*24小时在线',
      isWeChat: true
    },
    {
      icon: MessageCircle,
      title: 'WhatsApp',
      value: '+1 (424) 245-0624',
      description: '7*24小时在线',
      isWeChat: true
    },
    {
      icon: MessageCircle,
      title: '微信',
      value: 'OverseaBiz',
      description: '扫描下方二维码添加',
      isWeChat: true
    }
  ];

  const officeFeatures = [
    {
      icon: Clock,
      title: '营业时间',
      description: '周一至周五: 9:00 AM - 6:00 PM (PST)\n周六周日: 预约服务'
    },
    {
      icon: Building2,
      title: '办公地点',
      description: '2082 Business Center Dr, Suite 333\nIrvine, CA 92612\nUnited States'
    }
  ];

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    const { name, email, message } = formData;
    if (!name || !email || !message) {
      alert('Please fill in all fields.');
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    console.log("Validation passed.");
    // Send email (using EmailJS or similar service)
    // try {
    //   await fetch('https://api.emailjs.com/api/v1.0/email/send', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       service_id: 'YOUR_SERVICE_ID',
    //       template_id: 'YOUR_TEMPLATE_ID',
    //       user_id: 'YOUR_USER_ID',
    //       template_params: {
    //         name,
    //         email,
    //         message,
    //       },
    //     }),
    //   });
    //   alert('Your message has been sent!');
    // } catch (error) {
    //   alert('Failed to send message. Please try again later.');
    // }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-blue-900 text-white py-16 pt-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-4">联系我们</h1>
          <p className="text-xl text-blue-200">
            我们期待为您提供专业的服务和解答
          </p>
        </div>
      </div>

      {/* Contact Methods Grid */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {contactMethods.map((method, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow">
                <method.icon className="h-8 w-8 text-blue-600 mb-4" />
                <h3 className="text-2xl font-semibold mb-2">{method.title}</h3>
                <p className="text-gray-900 font-medium mb-2">{method.value}</p>
                <p className="text-gray-600 text-sm">{method.description}</p>
                {method.isWeChat && (
                  <div className="mt-4 bg-gray-50 p-4 rounded-lg text-center">
                    <p className="text-sm text-gray-600 mb-2">扫描添加{method.title}</p>
                    {/* Replace with actual QR code */}
                    <div
                      className="w-64 h-64 bg-gray-200 mx-auto rounded-lg "
                      style={{
                        backgroundImage: `url(${method.title === 'WhatsApp' ? whatsappImage : method.title === '微信' ? wechatImage : method.title === 'Telegram' ? telegramImage : ''})`,
                        backgroundSize: 'cover', // Adjusts the size of the image to fit within the div
                        backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                        backgroundPosition: 'center' // Centers the image within the div
                      }}
                    ></div>
                  </div>
            )}
          </div>
            ))}
        </div>
    </div>
      </section >

    {/* Office Information */ }
    < section className = "py-16 bg-white" >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="bg-gray-50 rounded-lg p-8">
            <h2 className="text-2xl font-bold mb-6">办公信息</h2>
            <div className="space-y-8">
              {officeFeatures.map((feature, index) => (
                <div key={index} className="flex">
                  <feature.icon className="h-6 w-6 text-blue-600 mr-4 flex-shrink-0" />
                  <div>
                    <h3 className="font-semibold mb-2">{feature.title}</h3>
                    <p className="text-gray-600 whitespace-pre-line">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-gray-100 rounded-lg overflow-hidden h-[400px]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.8887322238133!2d-117.8362!3d33.6761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdec05ec24c0b%3A0x7c3c684de9af8804!2s2082%20Business%20Center%20Dr%2C%20Irvine%2C%20CA%2092612!5e0!3m2!1sen!2sus!4v1647894321234!5m2!1sen!2sus"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      </section >

  {/* Contact Form
      <section id="contact-form" className="py-16">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-bold mb-6 text-center">发送咨询</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    姓名
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    邮箱
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  咨询内容
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={4}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                ></textarea>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="bg-blue-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
                >
                  发送信息
                </button>
              </div>
            </form>
          </div>
        </div>
      </section> */}
    </div >
  );
}