
import { useState } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const BillingForm = ({ billingAddress, setBillingAddress }: {
    billingAddress: any | null;
    setBillingAddress: (status: any | null) => void;
}) => {
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // Handle submit
    // const handleSubmit = (e: React.FormEvent) => {
    //     e.preventDefault();
    //     if (validate()) {
    //         onBillingSubmit(billingAddress); // Pass valid data to parent
    //     }
    // };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value });
    }

    const validateField =(fieldName:string)=>{
        let newErrors: { [key: string]: string } = {};

        if(!billingAddress[fieldName].trim()){
            newErrors[fieldName] = `${fieldName} is required`;
            setErrors(newErrors);
        }else{
            delete newErrors[fieldName];
            setErrors(newErrors);
        }

        //validate email
        if(fieldName ==='email' && !/\S+@\S+\.\S+/.test(billingAddress.email)){
            newErrors[fieldName] = `Valid email is required`;
            setErrors(newErrors);
        }else if(fieldName ==='email' && /\S+@\S+\.\S+/.test(billingAddress.email)){
            delete newErrors[fieldName];
            setErrors(newErrors);
        }
        
    }
    return (
        <div className="p-4 bg-white shadow-md rounded-md mb-4">
            <h2 className="text-lg font-semibold mb-4">Billing Information</h2>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">First Name 名字</label>
                    <input type="text" name="firstName" value={billingAddress.firstName} onChange={handleChange}
                        onKeyUp={() => { validateField('firstName') }}
                        className="mt-1 p-2 w-full border rounded-md" />
                    {errors.firstName && <p className="text-red-500 text-xs">{errors.firstName}</p>}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Last Name 姓氏</label>
                    <input type="text" name="lastName" value={billingAddress.lastName} onChange={handleChange}
                        onKeyUp={() => { validateField('lastName') }}
                        className="mt-1 p-2 w-full border rounded-md" />
                    {errors.lastName && <p className="text-red-500 text-xs">{errors.lastName}</p>}
                </div>
            </div>

            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Company (Optional) 公司</label>
                <input type="text" name="company" value={billingAddress.company} onChange={handleChange}
                    className="mt-1 p-2 w-full border rounded-md" />
            </div>

            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Address 地址</label>
                <input type="text" name="address" value={billingAddress.address} onChange={handleChange}
                    onKeyUp={() => { validateField('address') }}
                    className="mt-1 p-2 w-full border rounded-md" />
                {errors.address && <p className="text-red-500 text-xs">{errors.address}</p>}
            </div>

            <div className="grid grid-cols-3 gap-4 mt-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">City 城市</label>
                    <input type="text" name="city" value={billingAddress.city} onChange={handleChange}
                        onKeyUp={() => { validateField('city') }}
                        className="mt-1 p-2 w-full border rounded-md" />
                    {errors.city && <p className="text-red-500 text-xs">{errors.city}</p>}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">State 省</label>
                    <input type="text" name="state" value={billingAddress.state} onChange={handleChange}
                        onKeyUp={() => { validateField('state') }}
                        className="mt-1 p-2 w-full border rounded-md" />
                    {errors.state && <p className="text-red-500 text-xs">{errors.state}</p>}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">ZIP Code 邮编</label>
                    <input type="text" name="zip" value={billingAddress.zip} onChange={handleChange}
                        onKeyUp={() => { validateField('zip') }}
                        className="mt-1 p-2 w-full border rounded-md" />
                    {errors.zip && <p className="text-red-500 text-xs">{errors.zip}</p>}
                </div>
            </div>

            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Country 国家</label>
                <input type="text" name="country" value={billingAddress.country} onChange={handleChange}
                    onKeyUp={() => { validateField('country') }}
                    className="mt-1 p-2 w-full border rounded-md" />
                {errors.country && <p className="text-red-500 text-xs">{errors.country}</p>}
            </div>

            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Phone Number 电话</label>
                <PhoneInput
                    className="mt-1 p-2 w-full border rounded-md"
                    name="phoneNumber"
                    defaultCountry="us"
                    value={billingAddress.phoneNumber}
                    onChange={(phone) => { setBillingAddress({ ...billingAddress, phoneNumber: phone }); setPhone(phone); }}
                />
            </div>

            <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Email 邮箱</label>
                <input type="email" name="email" value={billingAddress.email} onChange={handleChange}
                    onKeyUp={() => { validateField('email') }}
                    className="mt-1 p-2 w-full border rounded-md" />
                {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
            </div>

        </div>

    );
};

export default BillingForm;