import 'react-social-icons/tiktok'
import { services } from '@/components/Data/services';
import CompanyCard from '../Company/ProductCard';
import ContactUsWedge from '../utils/ContactUsWedge';

// get services
const Stockservice = services.filter((service: { name: string; }) => service.name == "Stock Investment");
const Coinservice = services.filter((service: { name: string; }) => service.name == "Coin Trading");

//get products
const StockserviceProducts = Stockservice[0].products;
const CoinserviceProducts = Coinservice[0].products;


export default function EAccountPage() {
    return (
        <div className="min-h-screen bg-gray-50">
            <div className="bg-blue-900 text-white py-16 pt-32">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-bold mb-4">金融账号开通</h1>
                    <p className="text-xl text-blue-200">我们可以帮助您创建账户，以用于股票投资和 加密货币交易</p>
                </div>
            </div>

            {/* Stock Investment Section */}
            <section className="py-16" id="us-company-registration">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-gray-900 mb-8">
                        股票投资账户
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {StockserviceProducts.map((product: { product: string; price: number, minDay: string; maxDay: string; }) => {
                            const details = { code: "", name: "", price: 0, nameEn: "", minDay: 0, maxDay: 0 };
                            return (
                                <CompanyCard details={details} product={product} service="Stock Investment 股票投资账户" />
                            );
                        })}

                    </div>
                </div>
            </section>

            {/* coin Trading Section */}
            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-gray-900 mb-4">
                        加密货币交易账户
                    </h2>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {CoinserviceProducts.map((product: { product: string; price: number, minDay: string; maxDay: string; }) => {
                            const details = { code: "", name: "", price: 0, nameEn: "", minDay: 0, maxDay: 0 };
                            return (
                                <CompanyCard details={details} product={product} service="Coin Trading 加密货币交易账户" />
                            );
                        })}
                    </div>
                </div>
            </section>

            <ContactUsWedge />
        </div>
    );
}