export default function CompanyType() {
    return (
        <div>
            {/* Company Types Section */}
            <section className="py-16 bg-gray-50">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-gray-900 mb-12">公司类型</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="bg-white rounded-lg shadow-lg p-8">
                            <h3 className="text-2xl font-bold mb-4">LLC</h3>
                            <p className="text-gray-600 mb-6">
                                Limited Liability Company, 有限公司. 该类型的公司无法在美国上市, 也无需召开股东或成员会议. 适合私人及小型企业.
                            </p>
                            <div className="space-y-2">
                                <p className="text-sm text-gray-600">1. 州政府费用(state filing fee)</p>
                                <p className="text-sm text-gray-600">2. 注册代理(registered agent) 12个月</p>
                                <p className="text-sm text-gray-600">3. 注册证书(articles of organization)</p>
                                <p className="text-sm text-gray-600">4. 注册人申明(statement of the organizer)</p>
                                <p className="text-sm text-gray-600">5. 联邦税号(EIN)</p>
                                <p className="text-sm text-gray-600">6. 股份协议(operating agreement)</p>
                            </div>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-8">
                            <h3 className="text-2xl font-bold mb-4">C Corp</h3>
                            <p className="text-gray-600 mb-6">
                                C Corporation, 股份有限公司. 该类型的公司可在美国发行股票及上市. 每年需要召开股东大会及做会议记录.
                            </p>
                            <div className="space-y-2">
                                <p className="text-sm text-gray-600">1. 州政府费用(state filing fee)</p>
                                <p className="text-sm text-gray-600">2. 注册代理(registered agent) 12个月</p>
                                <p className="text-sm text-gray-600">3. 注册证书(articles of incorporation)</p>
                                <p className="text-sm text-gray-600">4. 注册人申明(statement of the incorporator)</p>
                                <p className="text-sm text-gray-600">5. 联邦税号(EIN)</p>
                                <p className="text-sm text-gray-600">6. 公司章程(corporation bylaws)</p>
                                <p className="text-sm text-gray-600">7. 会议纪要(minutes of first directors meeting)</p>
                                <p className="text-sm text-gray-600">8. 股票证书(stock certificate)</p>
                                <p className="text-sm text-gray-600">9. 股票账目(stock transfer ledger)</p>
                            </div>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-8">
                            <h3 className="text-2xl font-bold mb-4">S Corp</h3>
                            <p className="text-gray-600 mb-6">
                                S Corporation, 一种特殊税务身份, 避免双重征税, 但对股东人数和国籍有限制。
                            </p>
                            <div className="space-y-2">
                                <p className="text-sm text-red-600">To be determined</p>
                            </div>
                        </div>
                        <div className="bg-white rounded-lg shadow-lg p-8">
                            <h3 className="text-2xl font-bold mb-4">Sole Proprietorship</h3>
                            <p className="text-gray-600 mb-6">
                                个体经营由个人独资，无法律隔离，所有债务和收益归个人承担。
                            </p>
                            <div className="space-y-2">
                                <p className="text-sm text-red-600">To be determined</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}