import { AlertTriangle, DollarSign, CircleDollarSign } from 'lucide-react';
import ContactUsWedge from '../utils/ContactUsWedge';
import { Link } from 'react-router-dom';
import CompanyType from './CompanyType';
import { services } from '@/components/Data/services';
import ProductCard from './ProductCard';
import { CA, MX } from 'country-flag-icons/react/3x2';

export default function USCompanyPage() {

  const taxFreeStates = [
    { code: 'us-WY', name: '怀俄明州', nameEn: 'Wyoming' },
    { code: 'us-WA', name: '华盛顿州', nameEn: 'Washington' }
  ];

  const bankingStates = [
    { code: 'us-CA', name: '加利福尼亚州', nameEn: 'California' },
    { code: 'NY', name: '纽约州', nameEn: 'New York' }
  ];

  const otherCountryStates = [
    { code: 'CA', flag: CA, name: '加拿大', price: 1200, nameEn: 'Canada', learnMore: true },
    { code: 'MX', flag: MX, name: '墨西哥', price: 1200, nameEn: 'Mexico', learnMore: false }
  ];

  const otherStates = [
    {
      code: 'DE',
      name: '特拉华州',
      nameEn: 'Delaware',
      description: '保密性好, 公司法完善, 不在州内经营免所有州税.',
    }
  ];

  const maintenanceServices = [
    {
      icon: DollarSign,
      name: '基础公司报税',
    },
    {
      icon: CircleDollarSign,
      name: '复杂业务报税',
    }
  ];

  // get services
  const companyservice = services.filter((service: { name: string; }) => service.name == "US Company Registration");
  const OtherCountryCompanyservice = services.filter((service: { name: string; }) => service.name == "Other Country Company Registration");
  const taxService = services.filter((service: { name: string; }) => service.name == "US Company Tax");

  //get products
  const CompanyProducts = companyservice[0].products;
  const OtherCountryCompanyProducts = OtherCountryCompanyservice[0].products;
  const taxProducts = taxService[0].products;

  // filter us company products
  const taxFreeProducts = CompanyProducts?.filter((product: { type: string; }) => product.type === 'taxFree');
  const bankingProducts = CompanyProducts?.filter((product: { type: string; }) => product.type === 'banking');
  const otherStateProducts = CompanyProducts?.filter((product: { type: string; }) => product.type === 'other');

  return (
    <div className="min-h-screen bg-gray-50 ">
      {/* Hero Section */}
      <div className="bg-blue-900 text-white py-16 pt-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-4">美国公司服务</h1>
          <p className="text-xl text-blue-200 w-3/4 lg:w-full">
            我们提供美国各州的公司注册服务，包括公司注册、EIN（雇主识别号）申请，确保您的企业能够合法运营。
          </p>
          <p className="text-xl text-blue-200 mt-2">
            注册公司需提供 （护照） 和 （邮箱）。
          </p>
        </div>
      </div>

      {/* Alert Banner */}
      <div className="bg-yellow-50 border-b border-yellow-200">
        <div className="max-w-7xl mx-auto px-4 py-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="flex items-center">
              <AlertTriangle className="h-5 w-5 text-yellow-600 mr-2" />
              <p className="text-yellow-700">
                美国公司自2024年1月1号起需要向美国金融执法犯罪局申报实际控制人信息(BOI)
                <Link to="/boi" className="ml-2 font-medium underline">详情 &raquo;</Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Tax-Free States Section */}
      <section className="py-16" id="us-company-registration">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">
            无公司税(Corporate Tax)的6大免税州, 适合c-corp类型
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {taxFreeProducts.map((product: { product: string; price: number, minDay: string; maxDay: string; },index:number) => {
              const details = taxFreeStates.find((state) => state.name === product.product) || { code: "na", name: "N/A", price: 0, nameEn: "", minDay: 0, maxDay: 0 };
              return (
                <ProductCard details={details} product={product} service="US Company Registration 美国公司注册服务" key={index} />
              );
            })}
          </div>
        </div>
      </section>

      {/* Banking States Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            华美银行和国泰银行都有分行的6大州
          </h2>
          <p className="text-xl text-gray-600 mb-8">
            适合需要远程开立实体银行账户的客户
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {bankingProducts.map((product: { product: string; price: number, minDay: string; maxDay: string; },index:number) => {
              const details = bankingStates.find((state) => state.name === product.product) || { code: "", name: "", price: 0, nameEn: "", minDay: 0, maxDay: 0 };
              return (
                <ProductCard details={details} product={product} service="US Company Registration 美国公司注册服务" key={index} />
              );
            })}
          </div>
        </div>
      </section>

      {/* Other Popular States Section */}
      <section className="py-16" id="us-company-registration">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">
            其他优势州
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            {otherStateProducts.map((product: { product: string; price: number, minDay: string; maxDay: string; },index:number) => {
              const details = otherStates.find((state) => state.name === product.product) || { code: "", name: "", description: "", nameEn: "", minDay: 0, maxDay: 0 };
              return (
                <ProductCard details={details} product={product} service="US Company Registration 美国公司注册服务" key={index} />
              );
            })}

          </div>
        </div>
      </section>

      <CompanyType />

      {/* Maintenance Services Section */}
      <section id="us-company-maintenance" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-12">美国公司税务服务</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {taxProducts.map((product: { product: string; price: number; exactPrice: boolean; },index:number) => {
              const details = maintenanceServices.find((service) => service.name === product.product) || { icon: DollarSign, title: "", description: "" };
              return (
                <div>
                  <ProductCard details={details} product={product} service="US Company Tax 美国公司税务服务" key={index+product.product} />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      {/* Other Country Services Section */}
      <section id="other-company-registration" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            其他国家公司注册
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {OtherCountryCompanyProducts.map((product: { product: string; price: number, minDay: string; maxDay: string; },index:number) => {
              const details = otherCountryStates.find((state) => state.name === product.product) || { code: "", name: "", learnMore: false, price: 0, nameEn: "", minDay: 0, maxDay: 0 };
              return (
                <ProductCard details={details} product={product} service="Other Country Company Registration 其他国家注册服务" key={index} />
              );
            })}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-16 bg-gray-50">
        <ContactUsWedge />
      </section>
    </div>
  );
}