import { useLanguage } from '../../context/LanguageContext';

export default function WhyChooseUs() {
  const { t } = useLanguage();

  return (
    <div className="py-20 bg-blue-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">{t('whyChooseUs.title')}</h2>
        <div className="grid md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="text-4xl font-bold mb-2">15+</div>
            <div className="text-xl">{t('whyChooseUs.years')}</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold mb-2">5000+</div>
            <div className="text-xl">{t('whyChooseUs.companies')}</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold mb-2">98%</div>
            <div className="text-xl">{t('whyChooseUs.satisfaction')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}