import { Building2, FileText, Users, Coins, RefreshCw } from 'lucide-react';
import ContactUsWedge from '../utils/ContactUsWedge';

import ContactButton from '../utils/ContactButton';

export default function OTCPage() {

  const requirements = [
    {
      icon: Building2,
      title: '公司结构信息',
      description: '需要提供公司的股东、董事、高管及其持股比例。'
    },
    {
      icon: Users,
      title: '监管机构的申请表',
      description: '提交给监管机构的官方表格，包含公司基本信息及申请 OTC 交易服务的相关内容。'
    },
    {
      icon: FileText,
      title: '  AML/KYC 政策文件',
      description: '需要提供反洗钱（AML）和了解客户（KYC）合规政策。'
    },
    {
      icon: FileText,
      title: '  商业计划书',
      description: '概述公司的 OTC 业务模式。'
    }
  ];

  const searchMethods = [
    {
      title: '注册编号',
      description: 'OTC REGISTRATION NUMBER'
    },
    {
      title: '公司名字',
      description: 'LEGAL NAME (如 Coinbase Inc.)'
    },
    {
      title: '对外名字',
      description: 'DBA NAME (如 Binance.us)'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-blue-900 text-white py-16 pt-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-4">OTC </h1>
          {/* <p className="text-xl">OTC registration. 提供个人和公司承兑服务。</p> */}
        </div>
      </div>

      {/* Main Service Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="card bg-white rounded-lg shadow-lg p-8">
            <div className="flex items-center justify-between mb-8">
              <div>
                <h2 className="text-3xl font-bold text-gray-900">OTC 承兑申请服务</h2>
                {/* <p className="text-gray-600 mt-2">时间: 1-2 周</p> */}
              </div>
              <ContactButton/>
              {/* <span className="text-3xl font-bold text-red-600">$1,000</span> */}
            </div>
            <div className="bg-blue-50 rounded-lg p-6">
              <p className="text-gray-800">
                可用于申请OTC的主体: 美国公司(或其他国家注册的公司)
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Requirements Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">申请OTC所需材料</h2>
          <p className="text-gray-600 mb-8">
            OTC的申请没有限制公司注册的属地. 建议注册一个美国公司来申请为宜.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {requirements.map((req, index) => (
              <div key={index} className="bg-gray-50 rounded-lg p-6">
                <req.icon className="h-12 w-12 text-blue-600 mb-4" />
                <h3 className="text-xl font-semibold mb-2">{req.title}</h3>
                <p className="text-gray-600">{req.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Certificate Search Section */}
      {/* <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">OTC证书查询</h2>
          <div className="bg-white rounded-lg shadow-lg p-8">
            <div className="flex items-center mb-6">
              <Search className="h-6 w-6 text-blue-600 mr-2" />
              <p className="text-gray-800">
                OTC注册完成后可以在美国财政部下属的金融犯罪执法局官网FinCEN.gov进行查询
              </p>
            </div>
            <div className="space-y-4">
              <p className="text-gray-700 font-medium mb-4">可用如下三种方式的任意一种进行查询:</p>
              {searchMethods.map((method, index) => (
                <div key={index} className="bg-gray-50 rounded-lg p-4">
                  <p className="font-medium">{index + 1}. {method.title}</p>
                  <p className="text-gray-600 text-sm">{method.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      {/* Detail Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="card bg-blue-50 rounded-lg p-8">
            <div className="flex items-center mb-4">
              <Coins className="h-8 w-8 text-blue-600 mr-2" />
              <h2 className="text-2xl font-bold text-gray-900">OTC 支持多国货币</h2>
            </div>
            <p className="text-gray-800">
              USD（美元）、RMB（人民币）、CAD（加元）、MXN（墨西哥比索）
            </p>
          </div>
        </div>
      </section>


      {/* Contact Section */}
      <section className="py-16 bg-white">
        <ContactUsWedge />
      </section>
    </div>
  );
}