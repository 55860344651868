
import ContactUsWedge from '../utils/ContactUsWedge';
import ContactButton from '../utils/ContactButton';
const platforms = [
  {
    name: 'Stripe',
    website: 'https://stripe.com',
    conditions: [
      'SSN (我们提供)',
      '美国公司',
      '美国银行账户',
      '公司网站 (Optional)',
    ],
    pricing: [],
  },
  {
    name: 'Cash App',
    website: 'https://cash.app/',
    conditions: [], // Details can remain blank for now
    pricing: [], // Details can remain blank for now
  },
  {
    name: 'PayPal',
    website: 'https://www.paypal.com/',
    conditions: [], // Details can remain blank for now
    pricing: [], // Details can remain blank for now
  },
  {
    name: 'Zelle',
    website: 'www.zelle.com',
    conditions: [], // Details can remain blank for now
    pricing: [], // Details can remain blank for now
  },
  {
    name: 'Wire Transfer',
    website: 'Contact us',
    conditions: [], // Details can remain blank for now
    pricing: [], // Details can remain blank for now
  },
  {
    name: 'Authorize',
    website: 'https://www.authorize.net/',
    conditions: [], // Details can remain blank for now
    pricing: [], // Details can remain blank for now
  },
];

// // {
//     name: 'Shopify Payment',
//     website: 'https://www.shopify.com/payments',
//     conditions: [], // Details can remain blank for now
//     pricing: [], // Details can remain blank for now
//   },
export default function EPaymentPage() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-blue-900 text-white py-16 pt-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-4">建立收款通道</h1>
          <p className="text-xl text-blue-200">Open a merchant account to collect payments from your customers.</p>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {platforms.map((platform, index) => (
          <div key={index} className="card flex flex-col justify-stretch items-left bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-semibold mb-2">{platform.name}</h2>
            {/* {platform.website && (
              <a href={platform.website} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline mb-2 block">
                {platform.website}
              </a>
            )} */}
            <div className="flex-grow">
              <p className="font-bold mb-1">申请条件:</p>
              <ul className="list-disc list-inside mb-4">
                {platform.conditions.length > 0 ? (
                  platform.conditions.map((condition, idx) => (
                    <li key={idx}>{condition}</li>
                  ))
                ) : (
                  <li>暂无信息</li>
                )}
              </ul>
            </div>
            {/* <p className="font-bold text-red-500">定价:</p> */}
            <ul className="list-disc list-inside space-y-2 bottom-0">
              {platform.pricing.length > 0 ? (
                platform.pricing.map((price, idx) => (
                  <li key={idx}>{price}</li>
                ))
              ) : (
                <ContactButton />
              )}
            </ul>
          </div>
        ))}
      </div>
      <ContactUsWedge />
    </div>
  );
}