
import { US, MX } from 'country-flag-icons/react/3x2'
import 'react-social-icons/tiktok'
import { services } from '@/components/Data/services';
import ContactUsWedge from '../utils/ContactUsWedge';
import CompanyCard from '../Company/ProductCard';

const ECommerceFlage =[
    {name:"墨西哥 TikTok 店铺注册", flag:MX},
    {name:"美国 TikTok 店铺注册", flag:US}
]

// get services
const Eservice = services.filter((service: { name: string; }) => service.name == "E-Commerce Account");
const EserviceProducts = Eservice[0].products;

export default function EAccountPage() {
    return (
        <div className="min-h-screen bg-gray-50">
            <div className="bg-blue-900 text-white py-16 pt-32">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-bold mb-4">创建电商账户</h1>
                    <p className="text-xl text-blue-200">我们可以帮助您创建海外 TikTok 账户:</p>
                </div>
            </div>
            <div id="e-account-content" className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-24 pt-32 pb-16 px-16 max-w-7xl justify-center mx-auto">
                {EserviceProducts.map((product: { product: string; price: number, minDay: string; maxDay: string; }) => {
                            const details = ECommerceFlage.find((flag)=> flag.name == product.product)
                            return (
                                <CompanyCard details={details} product={product} service="E-Commerce Account 电商账户开户" />
                            );
                        })}
            </div>
            <ContactUsWedge />
        </div>
    );
}