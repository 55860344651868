
import 'react-social-icons/tiktok'
import ContactUsWedge from '../utils/ContactUsWedge';
import { services } from '@/components/Data/services';
import CompanyCard from '../Company/ProductCard';

// get services
const VPNservice = services.filter((service: { name: string; }) => service.name == "VPN");
const VPNserviceProducts = VPNservice[0].products;

export default function VPNPage() {
    return (
        <div className="min-h-screen bg-gray-50">
            <div className="bg-blue-900 text-white py-16 pt-32">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-bold mb-4"> VPN</h1>
                    <p className="text-xl text-blue-200">我们提供专业的 VPN 服务，在美国采购和加设设备，并提供技术支持。</p>
                </div>
            </div>
            {/* VPN setup Section */}
            <section className="py-16 bg-gray-100">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-gray-900 mb-2">VPN 设备与服务报价：</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-24 pt-12 pb-16 px-16 max-w-7xl justify-center mx-auto ">
                        {VPNserviceProducts.map((product: { product: string; price: number, minDay: string; maxDay: string; }) => {
                            const details = { code: "", name: "", price: 0, nameEn: "", minDay: 0, maxDay: 0 };
                            return (
                                <CompanyCard details={details} product={product} service="VPN 设备与服务" />
                            );
                        })}
                    </div>
                </div>
            </section>

            <ContactUsWedge />
        </div>
    );
}