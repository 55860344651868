import { Link } from 'react-router-dom';
import ContactUsWedge from '../utils/ContactUsWedge';
import { Split } from 'lucide-react';
import ContactButton from '../utils/ContactButton';
import { services } from '@/components/Data/services';



export default function OffshorePage() {

  const jurisdictions = [
    {
      name: 'BVI（英属维尔京群岛）',
      nameEn: 'BVI',
      flag: 'VG',
      companyType: '商业公司 Business Company (BC)',
      minShareholders: 1,
      minDirectors: 1,
      taxRate: '0%',
      requirements: {
        localDirector: false,
        corporateShareholder: true,
        annualAudit: false,
        annualReturn: false
      },
      learnMore: false
    },
    {
      name: '开曼群岛',
      nameEn: 'CaymanIslands',
      flag: 'KY',
      companyType: '股份有限公司 Exempted Company',
      minShareholders: 1,
      minDirectors: 1,
      taxRate: '0%',
      requirements: {
        localDirector: false,
        corporateShareholder: true,
        annualAudit: false,
        annualReturn: true
      },
      learnMore: false
    },


  ];

  const useCases = [
    '跨境电商 (amazon)',
    '外贸soho',
    '自由职业freelancer',
    '境外资产配置',
    '税务规划'
  ];

  //get services
  const OffService = services.filter((service: { name: string; }) => service.name == "Offshore");
  const OffServiceProducts = OffService[0].products;

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-blue-900 text-white py-16 pt-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-6">离岸公司</h1>
          <div className="flex flex-wrap gap-4">
            {useCases.map((useCase, index) => (
              <span key={index} className="bg-white/10 px-4 py-2 rounded-full text-sm">
                {useCase}
              </span>
            ))}
          </div>
        </div>
      </div>

      {/* Jurisdictions Grid */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-32">
            {jurisdictions.map((jurisdiction, index) => {
              const productPrice = OffServiceProducts.filter((product: { product: string; }) => product.product == jurisdiction.name);
              return (
                <div key={index} className="card bg-white rounded-lg shadow-lg overflow-hidden">
                  <div className="p-6">
                    <div className="flex items-center justify-between mb-4">
                      <div>
                        <h3 className="text-2xl font-semibold flex items-center mb-2">
                          <span className="mr-2">
                            <img
                              className="h-6 w-6 mr-2"
                              alt={jurisdiction.flag}
                              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${jurisdiction.flag}.svg`}
                            />
                          </span>
                          {jurisdiction.name}
                        </h3>
                        <p className="text-sm text-gray-600 mt-1">{jurisdiction.companyType}</p>
                      </div>
                    </div>

                    <div className="space-y-3 mb-6">
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">股东人数(最少)</span>
                        <span className="font-medium">{jurisdiction.minShareholders}人</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">董事人数(最少)</span>
                        <span className="font-medium">{jurisdiction.minDirectors}人</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">公司税率(所得税)</span>
                        <span className="font-medium">{jurisdiction.taxRate}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">本地秘书</span>
                        <span className="font-medium">{jurisdiction.requirements.localDirector ? '✔︎' : '✘'}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">公司股东</span>
                        <span className="font-medium">{jurisdiction.requirements.corporateShareholder ? '✔︎' : '✘'}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">年审</span>
                        <span className="font-medium">{jurisdiction.requirements.annualAudit ? '✔︎' : '✘'}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">年报</span>
                        <span className="font-medium">{jurisdiction.requirements.annualReturn ? '✔︎' : '✘'}</span>
                      </div>
                    </div>

                    <div className="my-4">
                      <div className="text-2xl font-bold text-red-600 mb-4">${productPrice[0].price} USD</div>
                      {jurisdiction.learnMore && (
                        <Link to={`/offshore/${jurisdiction.nameEn}`} className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors">
                          了解详情 &raquo;
                        </Link>
                      )}
                      <Link
                        to={`/stripe`}
                        state={{ product: productPrice[0].product, service: OffService[0].name  + " 离岸公司注册服务", price: productPrice[0].price }}
                        className="bg-blue-800 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-red-700 transition-colors inline-flex items-center">
                        立即注册
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      {/* Separation Service Section */}
      <section className="py-16 bg-white">
        <div className="flex justify-between items-center bg-blue-50 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className=" rounded-lg p-8">
            <div className="flex items-center mb-4">
              <Split className="h-6 w-6 text-blue-600 mr-2" />
              <h2 className="text-2xl font-bold text-gray-900">离岸保险保单代理服务</h2>
            </div>
            <p className="text-gray-800 ml-12">
              提供资产隔离服务。
            </p>
          </div>
          <ContactButton />
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-16 bg-gray-50">
        <ContactUsWedge />
      </section>
    </div>
  );
}