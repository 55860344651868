import { useEffect, useState } from "react";

const env = import.meta.env;
const apiLoginId = env.VITE_AUTHORIZE_API_LOGIN_ID;
const transactionKey = env.VITE_AUTHORIZE_API_TRANSACTION_KEY;
const emptyBillingDetails = {
    firstName: "",
    lastName: "",
    company: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phoneNumber: "",
    email: "",
}
const emptyBankInfo= {cardNumber:"",expDate:""}
export default function ProcessPayment({ opaqueData, service, product, amount, billingDetails, setBillingDetails, paymentStatus, setPaymentStatus, bankInfo, setBankInfo}:
    {
        opaqueData: { dataDescriptor: string; dataValue: string };
        service: string;
        product: string;
        amount: number;
        billingDetails: any;
        paymentStatus: string | null;
        setPaymentStatus: (status: string | null) => void;
        setBillingDetails: (status: any | null) => void;
        bankInfo: any;
        setBankInfo: (status: any | null) => void;
    }) {

    const transactionRequest = {
        createTransactionRequest: {
            merchantAuthentication: {
                name: apiLoginId,
                transactionKey: transactionKey,
            },
            transactionRequest: {
                transactionType: "authCaptureTransaction",
                amount: amount,
                payment: {
                    opaqueData: {
                        dataDescriptor: opaqueData.dataDescriptor,
                        dataValue: opaqueData.dataValue,
                    },
                },
                order: {
                    description: `service: ${service}, product: ${product}`,
                },
                customer: {
                    email: billingDetails.email,
                },
                billTo: {
                    firstName: billingDetails.firstName,
                    lastName: billingDetails.lastName,
                    company: billingDetails.company,
                    address: billingDetails.address,
                    city: billingDetails.city,
                    state: billingDetails.state,
                    zip: billingDetails.zip,
                    country: billingDetails.country,
                    phoneNumber: billingDetails.phoneNumber,
                    email: billingDetails.email
                },
                transactionSettings: {
                    setting: [
                        {
                            settingName: "emailCustomer",
                            settingValue: "true",
                        },
                        {
                            settingName: "headerEmailReceipt",
                            settingValue: "Thank you for your payment! Our sales team will contact you shortly.",
                        }
                    ],
                },
            },
        },
    };


    const handlePayment = async () => {

        try {
            // create transaction
            const response = await fetch("https://api.authorize.net/xml/v1/request.api", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(transactionRequest),
            });

            const data = await response.json();

            if (data.transactionResponse?.responseCode === "1") {

                setBillingDetails(emptyBillingDetails);
                setBankInfo(emptyBankInfo);
                setPaymentStatus("Payment successful! Transaction ID: " + data.transactionResponse.transId);
            } else {

                alert("Payment failed: " + JSON.stringify(data.messages?.message[0]?.text));
            }
        } catch (error) {
            alert("Payment processing error:"+error+"\nPayment failed. Please try again.");
        }

    };

    return (
        <div className="flex flex-col items-center justify-center mt-6 w-full max-w-xl">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl mb-8">
                        <h2 className="text-xl mb-4 text-center">Payment Information</h2>
                        <div className="flex justify-between border-b px-4 mb-2">
                            <div className="mt-1 p-2 w-full text-left" >Name 姓名</div>
                            <div className="mt-1 p-2 w-full text-right" >{billingDetails.firstName} {billingDetails.lastName}</div>
                        </div>
                        <div className="flex justify-between border-b  px-4 mb-2">
                            <div className="mt-1 p-2 w-full text-left" >Email 邮箱</div>
                            <div className="mt-1 p-2 w-full text-right" >{billingDetails.email}</div>
                        </div>
                        <div className="flex justify-between border-b px-4 mb-2">
                            <div className="mt-1 p-2 w-full text-left" >Bank Card Information 卡号</div>
                            <div className="mt-1 p-2 w-full text-right" >{bankInfo.cardNumber}</div>
                        </div>
                        <div className="flex justify-between border-b  px-4 mb-2">
                            <div className="mt-1 p-2 w-full text-left" >Expriration Date 有效期</div>
                            <div className="mt-1 p-2 w-full text-right" >{bankInfo.expDate}</div>
                        </div>

                </div>
                {!paymentStatus && <h2 className="text-lg font-semibold mb-4 text-center">Processing Payment</h2>}
                {!paymentStatus && <button
                    id="process-button"
                    onClick={handlePayment}
                    className="w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                >
                    请确认后付款 ${amount}
                </button>}


                {paymentStatus && (
                    <div className="mt-4 text-left text-sm font-semibold text-gray-700">
                        {paymentStatus}
                        <div className="mt-4 text-center text-xl font-semibold text-gray-700">
                            Please Check your email for the receipt. 
                            Our team will contact you shortly.
                        </div>
                    </div>
                )}


            </div>
        </div>
    );
}