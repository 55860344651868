export default function CanadaOffShore() {
    return (
        <div className="min-h-screen bg-gray-50">
            {/* Hero Section */}
            <div className="bg-blue-900 text-white py-16 pt-32">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-bold mb-4">加拿大海外公司</h1>
                    <p className="text-xl">加拿大海外公司,注册在BC省。无国籍限制, 无需本地董事或股东。</p>
                </div>
            </div>

            {/* Requirements Section */}
            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto space-y-6 px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold mb-6">所需资料</h2>
                    <div className="flex flex-col md:flex-row justify-between mb-4 mr-8 gap-4">
                        <div className=" border border-gray-300 rounded-lg p-12 space-y-3 font-sans shadow-lg hover:shadow-xl transition-shadow">
                            <h2 className="text-xl font-bold mb-2">公司核名 (Name Request)</h2>
                            <p className="text-gray-600">注册BC省的公司必须先进行核名(Name Request). 名字核准通过后才可以进行公司注册.</p>
                            <p className="mt-4">名字组成必须包括</p>
                            <ul className="list-disc list-inside mt-2">
                                <li>个性化名字(如TRUMP)</li>
                                <li>主营业务形容词(如LOGISTICS)</li>
                                <li>后缀(如INCORPORATED/INC.或LIMITED/LTD.)</li>
                            </ul>
                        </div>
                        <div className=" border border-gray-300 rounded-lg p-12 space-y-3 font-sans shadow-lg hover:shadow-xl transition-shadow">
                            <h2 className="text-xl font-bold mb-2">其他资料</h2>
                            <p className="text-gray-600">公司名字按优先顺序提供3个</p>
                            <ol className="list-disc list-inside">
                                <li>公司名字 (如KARMEN LOGISTICS LIMITED)</li>
                                <li>注册地址 (加拿大BC省的地址)</li>
                                <li>董事和股东姓名 (最少1人, 可以多人)</li>
                                <li>原始股份数量和价格(如1,000股, 每股$1.00), 不用实缴</li>
                            </ol>
                        </div>
                        <div className="border border-gray-300 rounded-lg p-12 space-y-3 font-sans shadow-lg hover:shadow-xl transition-shadow">
                            <h2 className="text-xl font-bold mb-2">公司年报 (Annual Report)</h2>
                            <p className="text-gray-600">加拿大公司每年需要做一次年报, 可以在满1年的前2个月做.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}