import React from "react";

export const services= [
    {
        name: "US Company Registration",
        products: [
            { product: "华盛顿州", type:"taxFree", price: 800, exactPrice: true, minDay: 7, maxDay: 10 },
            { product: "怀俄明州", type:"taxFree", price: 650, exactPrice: true, minDay: 5, maxDay: 7 },
            { product: "加利福尼亚州", type:"banking", price: 650, exactPrice: true, minDay: 3, maxDay: 5 },
            { product: "纽约州", type:"banking", price: 750, exactPrice: true, minDay: 1, maxDay: 3 },
            { product: "特拉华州", type:"other", price: 780, exactPrice: true, minDay: 5, maxDay: 7 }
        ]
    },
    {
        name: "Other Country Company Registration",
        products: [
            {
                product: "墨西哥",
                price: 1200,
                exactPrice: true,
                minDay: 15, 
                maxDay: 20
            },
            {
                product: "加拿大",
                price: 1200,
                exactPrice: true,
                minDay: 15, 
                maxDay: 20
            },
        ],
    },
    {
        name: "Bank Account",
        products: [
            {
                product: "华美银行",
                price: 1500,
                exactPrice: true,
            },
            {
                product: "Chase/BOA 开户",
                price: 5000,
                exactPrice: true,
            },
            {
                product: "Chase/BOA 后续服务",
                price: 200,
                exactPrice: true,
            },
            {
                product: "虚拟银行",
                price: -1,
                exactPrice: true,
            },
        ],
    },
    {
        name: "Online Payment",
        products: [
            {
                product: ["Zelle 收款","Cash App 收款","Wire Transfer（电汇）收款","Stripe/PayPal 网关","Authorize 支付网关"],
                price: -1,
                exactPrice: true,
            },
        ],
    },
    {
        name: "OTC",
        products: [
            {
                product: "OTC 承兑申请服务",
                price: -1,
                exactPrice: true,
            },
        ],
    },
    {
        name: "USDT",
        products: [
            {
                product: "USDT 业务",
                price: -1,
                exactPrice: true,
            },
        ],
    },
    {
        name: "E-Commerce Account",
        products: [
            {
                product: "美国 TikTok 店铺注册",
                price: 2000,
                exactPrice: true,
            },
            {
                product: "墨西哥 TikTok 店铺注册",
                price: 5000,
                exactPrice: true,
            },
        ],
    },
    {
        name: "Stock Investment",
        products: [
            {
                product: "富途牛牛",
                price: 500,
                exactPrice: true,
            },
            {
                product: "嘉信理财",
                price: 300,
                exactPrice: true,
            },
            {
                product: "Robinhood",
                price: 250,
                exactPrice: true,
            },
            {
                product: "Wellfound",
                price: 400,
                exactPrice: true,
            },
        ],
    },
    {
        name: "Coin Trading",
        products: [
            {
                product: "Coinbase",
                price: 350,
                exactPrice: true,
            },
            {
                product: "Crypto",
                price: 300,
                exactPrice: true,
            },
            {
                product: "Binance",
                price: 450,
                exactPrice: true,
            },
        ],
    },
    {
        name: "US Company Tax",
        products: [
            {
                product: "基础公司报税",
                price: 1400,
                exactPrice: true,
            },
            {
                product: "复杂业务报税",
                price: 2000,
                exactPrice: false,
            },
        ],
    },
    {
        name: "Offshore",
        products: [
            {
                product: "BVI（英属维尔京群岛）",
                price: 2500,
                exactPrice: true,
            },
            {
                product: "开曼群岛",
                price: 3500,
                exactPrice: true,
            },
            {
                product: "资产隔离",
                price: -1,
                exactPrice: true,
            },
        ],
    },
    {
        name: "Insurance",
        products: [
            {
                product: "美国保险",
                price: -1,
                exactPrice: true,
            },
            {
                product: "香港保险",
                price: -1,
                exactPrice: true,
            },
        ],
    },
    {
        name: "Property",
        products: [
            {
                product: "美国地产开发",
                price: -1,
                exactPrice: true,
            },
            {
                product: "美国物业管理",
                price: -1,
                exactPrice: true,
            },
        ],
    },
    {
        name: "VPN",
        products: [
            {
                product: "VPN 服务器设备（标准版）",
                price: 1500,
                exactPrice: true,
            },
            {
                product: "VPN 服务器设备（高级版）",
                price: 2500,
                exactPrice: true,
            },
            {
                product: "VPN 专线配置",
                price: 500,
                exactPrice: true,
            },
            {
                product: "VPN 维护与技术支持（年费）",
                price: 800,
                exactPrice: true,
            },
        ],
    },
];

