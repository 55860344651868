import CompanyCard from '../Company/ProductCard';
import ContactUsWedge from '../utils/ContactUsWedge'
import { services } from '@/components/Data/services';
import { US } from 'country-flag-icons/react/3x2';
export default function BankingPage() {

  const traditionalBanks = [
    {
      name: '华美银行',
      nameEn: 'East West Bank',
      website: 'www.eastwestbank.com',
      description: '远程视频开户. 不支持Zelle',
      price: 1500
    }

  ];

  const bigBanks = [
    {
      name: 'Chase/BOA 开户',
      description: ' 一次性开户费',
    },
    {
      name: 'Chase/BOA 后续服务',
      description: '解决后期账户可能出现各类问题',
    }

  ];

  const digitalBanks = [
    {
      name: 'Wise',
      website: 'www.wise.com',

      description: '最佳多币种账户. 适合电商平台(如amazon)收款, 支持京东国际, 天猫国际.'
    },
    {
      name: 'Relay',
      website: 'www.relayfi.com',

      description: '可开子卡, 支持支票在线存款, 无账户管理费, 无最低存款要求.'
    },
    {
      name: 'Mercury',
      website: 'www.mercury.com',

      description: '无需社安号(SSN), 支持Stripe收款, 无账户管理费, 无最低存款要求.'
    },
    {
      name: 'Brex',
      website: 'www.brex.com',

      description: '无需社安号(SSN), 收付款全球0手续费, 可以绑定stripe, squareup收款.'
    }
  ];

  //get service
  const BankingService = services.filter((service: { name: string; }) => service.name == "Bank Account");
  const BankingServiceProducts = BankingService[0].products;

  //get product
  const EastWestBank = BankingServiceProducts.filter((product: { product: string; }) => product.product == "华美银行");
  const BigBanksService = BankingServiceProducts.filter((product: { product: string; }) => product.product != "华美银行" && product.product != "虚拟银行");

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-blue-900 text-white py-16 pt-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-4">美国银行开户 (个人账户/企业账户)</h1>
          <p className="text-xl">Open a personal account for yourself or a business checking account for your corporation (or LLC).</p>
        </div>
      </div>

      {/* Traditional Banks Section */}
      <section className="py-16">
        <div className="flex flex-col md:flex-row justify-between max-w-7xl mx-auto px-4 sm:px-6 lg:px-16">
          <div className=" grid grid-cols-1 w-full lg:w-1/3 mb-16 lg:mb-0">
            {EastWestBank.map((product: { product: string; price: number; }) => {
              const details = traditionalBanks.find((bank) => bank.name == product.product);
              return (
                <CompanyCard details={details} product={product} service="Banking Account 美国银行开户服务" />
              );
            })}
          </div>
          <div className="px-4 sm:px-6 sm:w-2/3 lg:px-16 ">
            <h2 className="text-3xl font-bold text-gray-900 lg:mb-8">华美银行开户要求</h2>
            <div className="bg-gray-50 rounded-lg p-6">
              <div className="space-y-4 max-w-3xl">
                <p className="text-gray-800">
                  1. 开户申请人须持有中国, 香港, 或澳门护照(针对非美国居民).
                </p>
                <p className="text-gray-800">
                  2. 公司需注册在上述银行有分行的州:
                </p>
                <p className="text-gray-600">
                  加州(CA) / 纽约州(NY) / 乔治亚州(GA) / 华盛顿州(WA) / 马萨诸塞州(MA) / 德克萨斯州(TX) / 内华达州(NV)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Big Four Banks Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-2">美国四大银行</h2>
          <p className="text-gray-600 mb-8">
            签字人( signer)代开户, 适合有一定实力和特定需求( Zelle)的客户. 无需护照, 不限注册州.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 w-full">
            {BigBanksService.map((product: { product: string; price: number; }) => {
              const details = bigBanks.find((bank) => bank.name == product.product) || { code: "", name: "", price: 0, nameEn: "", flag: US, };

              return (
                <CompanyCard details={details} product={product} service="Banking Account 美国银行开户及后续服务" />
              );
            })}
          </div>
        </div>
      </section>

      {/* Digital Banks Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">
            虚拟银行
            <span className="block text-lg font-normal text-gray-600 mt-4">
              4个银行开户全都无需SSN。 wise支持身份证开户, 其余3个都需要有护照。
            </span>
            <span className="block text-xl font-bold text-red-600 mt-2" >
              可根据需求定制，适用于国际支付和收款。
            </span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {digitalBanks.map((bank, index) => (
              <div key={index} className="card bg-white rounded-lg shadow-lg overflow-hidden transition-shadow">
                <div className="p-6">
                  <div className="flex items-center justify-between mb-4">
                    <h3 className="text-xl font-semibold">{bank.name}</h3>
                    {/* <span className="text-2xl font-bold text-red-600">${bank.price}</span> */}
                  </div>
                  {/* <a href={`https://${bank.website}`} target="_blank" rel="noopener noreferrer" 
                     className="text-blue-600 hover:text-blue-800 text-sm block mb-4">
                    {bank.website}
                  </a> */}
                  <p className="text-gray-600">{bank.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-16 bg-gray-50">
        <ContactUsWedge />
      </section>
    </div>
  );
}