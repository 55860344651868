import { Clock, ArrowRight } from 'lucide-react';

export default function NewsPage() {


  const articles = [
    {
      title: '离岸规划, 你需要知道的3种税收体系',
      image: 'https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?auto=format&fit=crop&q=80',
      category: '离岸规划',
      readTime: '10 min read',
      description: '了解全球税收体系的差异, 为您的资产配置做出最优选择.'
    },
    {
      title: '最佳多币种账户, 同时支持个人和公司开户',
      image: 'https://images.unsplash.com/photo-1563013544-824ae1b704d3?auto=format&fit=crop&q=80',
      category: '银行账户',
      readTime: '8 min read',
      description: '详细介绍Wise(前TransferWise)的多币种账户功能和开户流程.'
    },
    {
      title: '离岸公司应该注册在哪里? 离岸税务详解',
      image: 'https://images.unsplash.com/photo-1526304640581-d334cdbbf45e?auto=format&fit=crop&q=80',
      category: '离岸公司',
      readTime: '12 min read',
      description: '各大离岸司法管辖区的优劣对比, 助您选择最适合的注册地.'
    },
    {
      title: '如何获取美国个人税号ITIN (银行开户必备)',
      image: 'https://images.unsplash.com/photo-1554224154-26032ffc0d07?auto=format&fit=crop&q=80',
      category: '税务规划',
      readTime: '7 min read',
      description: '详细解读ITIN申请流程, 为您的美国银行开户做好准备.'
    },
    {
      title: '小额跨境汇款至中国有哪些途径?',
      image: 'https://images.unsplash.com/photo-1580048915913-4f8f5cb481c4?auto=format&fit=crop&q=80',
      category: '跨境支付',
      readTime: '6 min read',
      description: '各类跨境汇款方式的优劣对比, 助您选择最优汇款途径.'
    },
    {
      title: '注册美国公司, 该选哪个州? (三大热门州对比)',
      image: 'https://images.unsplash.com/photo-1551009175-b313f350eb7c?q=80&w=2071?auto=format&fit=crop&q=80',
      category: '美国公司',
      readTime: '9 min read',
      description: '深入分析特拉华州, 怀俄明州和内华达州的优势与劣势.'
    },
    {
      title: '投资美股如何选择合适的券商开户?',
      image: 'https://images.unsplash.com/photo-1611974789855-9c2a0a7236a3?auto=format&fit=crop&q=80',
      category: '投资理财',
      readTime: '8 min read',
      description: '美股券商对比分析, 助您选择最适合的开户平台.'
    },
    {
      title: '通过第二身份规避CRS (加勒比护照购买攻略)',
      image: 'https://images.unsplash.com/photo-1454496406107-dc34337da8d6?q=80&w=2000?auto=format&fit=crop&q=80',
      category: '第二身份',
      readTime: '11 min read',
      description: '详解加勒比护照项目, 为您的资产规划提供新思路.'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="bg-blue-900 text-white py-16 pt-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold mb-4">最新文章</h1>
          <p className="text-xl text-blue-200">获取最新的离岸金融, 公司注册和税务规划资讯</p>
        </div>
      </div>

      {/* Articles Grid */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {articles.map((article, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
                <div className="relative h-48 overflow-hidden">
                  <img 
                    src={article.image} 
                    alt={article.title}
                    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                  />
                  <div className="absolute top-4 left-4">
                    <span className="bg-blue-600 text-white px-3 py-1 rounded-full text-sm">
                      {article.category}
                    </span>
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2 hover:text-blue-600 transition-colors">
                    {article.title}
                  </h3>
                  <p className="text-gray-600 mb-4 line-clamp-2">
                    {article.description}
                  </p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center text-sm text-gray-500">
                      <Clock className="h-4 w-4 mr-1" />
                      {article.readTime}
                    </div>
                    <button className="text-blue-600 hover:text-blue-800 flex items-center text-sm font-medium">
                      阅读全文 
                      <ArrowRight className="h-4 w-4 ml-1" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Newsletter Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-blue-900 rounded-2xl text-white p-8 md:p-12">
            <div className="max-w-2xl mx-auto text-center">
              <h2 className="text-3xl font-bold mb-4">订阅我们的新闻资讯</h2>
              <p className="text-blue-200 mb-8">
                及时获取最新的离岸金融资讯, 税务规划建议和行业动态
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center">
                <input
                  type="email"
                  placeholder="请输入您的邮箱"
                  className="px-6 py-3 rounded-lg text-gray-900 flex-1 max-w-md"
                />
                <button className="bg-red-600 text-white px-8 py-3 rounded-lg font-semibold hover:bg-red-700 transition-colors">
                  立即订阅
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}