
export default function PaymentSuccess() {
    

    return (
        <div className="min-h-screen bg-gray-50  ">
            <div className="flex flex-col items-center justify-center  pt-64">
                <h1 className="text-3xl font-bold text-center p-8">Payment Cancelled.</h1>
            </div>
        </div>

    )
}