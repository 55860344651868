import { useEffect, useState } from "react";
import ProcessPayment from "./ProcessPayment";
import BillingForm from "./BillingForm";
import { useLocation } from 'react-router-dom';


const emptyBillingDetails = {
    firstName: "",
    lastName: "",
    company: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phoneNumber: "",
    email: "",
}

export default function sampleCharge() {
    const [opaqueData, setOpaqueData] = useState({ dataDescriptor: "", dataValue: "" });
    const [dataDescriptor, setDataDescriptor] = useState("");
    const [dataValue, setDataValue] = useState("");

    const env = import.meta.env;
    const apiLoginID = env.VITE_AUTHORIZE_API_LOGIN_ID;
    const transactionKey = env.VITE_AUTHORIZE_API_TRANSACTION_KEY;
    const signatureKey = env.VITE_AUTHORIZE_SIGNATURE_KEY;
    const clientKey = env.VITE_AUTHORIZE_PUBLIC_CLIENT_KEY;
    const [paymentStatus, setPaymentStatus] = useState<string | null>(null);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [billingDetails, setBillingDetails] = useState({});
    const [billingAddress, setBillingAddress] = useState(emptyBillingDetails);
    const [bankInfo, setBankInfo] = useState({})
    const location = useLocation();
    const { product, service, price } = location.state || { product: "empty product", service: "empty service", price: 0 }

    // Loading the JavaScript Library (with the Hosted Form) for Production
    // https://developer.authorize.net/api/reference/features/acceptjs.html
    useEffect(() => {
        // Ensure the script is loaded only once
        const scriptId = "authorize-net-script";
        if (!document.getElementById(scriptId)) {
            const script = document.createElement("script");
            script.src = "https://js.authorize.net/v3/AcceptUI.js";
            script.id = scriptId;
            script.async = true;
            script.onload = () => {
                console.log("Authorize.Net script loaded successfully.");
            };
            script.onerror = () => {
                console.error("Failed to load Authorize.Net script.");
            };
            document.body.appendChild(script);
        }

        //  Explicitly assign responseHandler to window
        (window as any).responseHandler = function (response: any) {

            if (response.messages.resultCode === "Error") {
                alert("Payment Error:" + response.messages.message);
            } else {
                // Process the opaqueData (send it to Authorize.Net's API)
                paymentFormUpdate(response.opaqueData, response.encryptedCardData);
            }
        };
        return () => {
            delete (window as any).responseHandler; // Cleanup to avoid conflicts
        };
    }, []);

    function paymentFormUpdate(opaqueData: { dataDescriptor: any; dataValue: any; }, encryptedCardData: { cardNumber: any; expDate: any; }) {
        setBankInfo(encryptedCardData);
        setDataDescriptor(opaqueData.dataDescriptor);
        setDataValue(opaqueData.dataValue);
        setOpaqueData(opaqueData);
        setPaymentStatus(null);

    }

    // Validate form
    const validate = () => {
        let newErrors: { [key: string]: string } = {};
        if (!billingAddress.firstName.trim()) newErrors.firstName = "First name 名字 is required";
        if (!billingAddress.lastName.trim()) newErrors.lastName = "Last name 姓氏 is required";
        if (!billingAddress.country.trim()) newErrors.country = "Country 国家 is required";
        if (!billingAddress.phoneNumber.trim()) newErrors.phoneNumber = "Phone number 手机 is required";
        if (!billingAddress.email.trim() || !/\S+@\S+\.\S+/.test(billingAddress.email)) {
            newErrors.email = "Valid email 有效邮箱 is required";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // billTo section must set before authorize button click
    const handlePaymentClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault(); // Prevent default form submission
        if (!validate()) {
            alert("请填写所需信息. Please fill out the required information.");
            return;
        }
        console.log("Validation passed. Proceeding to payment...");

        // set billTo section
        setBillingDetails(billingAddress);
        // Allow payment process to continue
        document.getElementById("payment-button")?.click();

    }

    return (
        <div className="min-h-screen bg-gray-50  ">
            <div className="flex flex-col items-center justify-center  pt-32">

                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl mb-8">
                        <h2 className="text-xl mb-2 text-center">Product Information</h2>
                        <p className="text-lg text-center text-gray-600 mb-8"> 请确认商品信息</p>
                        <div className="flex justify-between border-b px-4 mb-2">
                            <div className="mt-1 p-2 w-full w-1/3 text-left" >Product 产品</div>
                            <div className="mt-1 p-2 w-full w-2/3 text-right" >{product}</div>
                        </div>
                        <div className="flex justify-between border-b  px-4 mb-2">
                            <div className="mt-1 p-2 w-full w-1/3 text-left" >Service 服务</div>
                            <div className="mt-1 p-2 w-full w-2/3 text-right" >{service}</div>
                        </div>
                        <div className="flex justify-between border-b px-4 mb-2">
                            <div className="mt-1 p-2 w-full w-1/3 text-left" >Price 价格</div>
                            <div className="mt-1 p-2 w-full w-2/3 text-right" >${price}</div>
                        </div>
                    </div>

                    {!dataValue && !dataDescriptor && <div>
                        <h2 className="text-xl mb-2 text-center">Customer Information</h2>
                        <p className="text-lg text-center text-gray-600 mb-4"> 请填写顾客信息</p>
                        <form id="paymentForm"
                            method='POST'
                            target="_self"
                        >
                            <input type="hidden" name="dataValue" id="dataValue" value={dataValue} />
                            <input type="hidden" name="dataDescriptor" id="dataDescriptor" value={dataDescriptor} />

                            <BillingForm billingAddress={billingAddress} setBillingAddress={setBillingAddress} />
                            {/* Show validation errors */}
                            {Object.keys(errors).length > 0 && (
                                <div className="mt-4 p-2 bg-red-100 text-red-600 rounded">
                                    {Object.values(errors).map((error, index) => (
                                        <p key={index}>{error}</p>
                                    ))}
                                </div>
                            )}

                            <button
                                type="submit"
                                onClick={handlePaymentClick}
                                className="mt-4 w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                            >
                                前往付款
                            </button>
                            <p className="mt-2 text-sm text-gray-600"> 若点击“前往付款”后，无付款弹窗出现，请刷新页面</p>
                            <p className="mt-2 text-sm text-gray-600"> if no card information shows up, please refresh the page.</p>
                            <button
                                type="button"
                                id="payment-button"
                                className="hidden AcceptUI w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                data-billingaddressoptions='{"show":false, "required":false}'
                                data-apiloginid={apiLoginID}
                                data-clientkey={clientKey}
                                data-acceptuiformbtntxt="Submit"
                                data-acceptuiformheadertxt="Card Information"
                                data-responsehandler="responseHandler"
                            />
                        </form>
                    </div>}
                </div>
                {dataValue && dataDescriptor &&
                    <ProcessPayment opaqueData={opaqueData} service={service} product={product} amount={price} billingDetails={billingDetails} setBillingDetails={setBillingAddress} paymentStatus={paymentStatus} setPaymentStatus={setPaymentStatus} bankInfo={bankInfo} setBankInfo={setBankInfo} />
                }
            </div>

        </div>

    );
}