import { useState } from "react";
import { Link } from "react-router-dom";
import { Shield, Menu, X } from "lucide-react";
import { useLanguage } from "../../context/LanguageContext";
import LanguageSwitcher from "../utils/LanguageSwitcher";

export default function NavbarMobile() {
    const { t } = useLanguage();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState<string | null>(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        setActiveMenu(null); // Reset active sub-menu when closing the menu
    };

    const toggleSubMenu = (menuName: string) => {
        setActiveMenu(activeMenu === menuName ? null : menuName);
    };
    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <nav className="bg-blue-900 text-white fixed w-full z-50">
            <div className="container mx-auto px-4 flex justify-between items-center h-16">
                <Link to="/" className="flex items-center">
                    <Shield className="h-8 w-8 text-red-500" />
                    <span className="ml-2 text-lg font-bold">Oversea Business Services</span>
                </Link>
                <button
                    className="text-white lg:hidden"
                    onClick={toggleMenu}
                    aria-label="Toggle Menu"
                >
                    {isMenuOpen ? <X className="h-8 w-8" /> : <Menu className="h-8 w-8" />}
                </button>
            </div>

            {isMenuOpen && (
                <div className="bg-blue-900 text-white py-4 lg:hidden">
                    <div className="space-y-2 text-center">
                        {/*US Company */}
                        <div className="px-4 py-2">
                            <button
                                className={`w-full hover:text-red-400 ${activeMenu === "company" ? 'text-red-400' : ''}`}
                                onClick={() => toggleSubMenu("company")}
                            >
                                {t("nav.Company")}
                            </button>
                            {activeMenu === "company" && (
                                <div className="pl-4 space-y-1">
                                    <Link to="/company" className="block pt-4 hover:text-red-400" onClick={() => { toggleMenu(); scrollToSection('us-company-registration') }}>
                                        {t("nav.Company.us.registration")}
                                    </Link>
                                    <Link to="/company" className="block pt-2 hover:text-red-400" onClick={() => { toggleMenu(); scrollToSection('us-company-maintenance') }}>
                                        {t("nav.Company.us.maintenance")}
                                    </Link>
                                </div>
                            )}
                        </div>

                        {/*Oversea Company */}
                        <div className="px-4 py-2">
                            <button
                                className={`w-full hover:text-red-400 ${activeMenu === "oversea" ? 'text-red-400' : ''}`}
                                onClick={() => toggleSubMenu("oversea")}
                            >
                                {t("nav.oversea")}
                            </button>
                            {activeMenu === "oversea" && (
                                <div className="pl-4 space-y-1">
                                    <Link to="/company" className="block pt-4 hover:text-red-400" onClick={() => { toggleMenu(); scrollToSection('other-company-registration') }}>
                                        {t("nav.Company.out.registration")}
                                    </Link>
                                    <Link to="/offshore" className="block pt-2 hover:text-red-400" onClick={toggleMenu}>
                                        {t("nav.offshore")}
                                    </Link>
                                </div>
                            )}
                        </div>

                        {/* payment */}
                        <div className="px-4 py-2">
                            <button
                                className={`w-full hover:text-red-400 ${activeMenu === "payment" ? 'text-red-400' : ''}`}
                                onClick={() => toggleSubMenu("payment")}
                            >
                                {t("nav.payment")}
                            </button>
                            {activeMenu === "payment" && (
                                <div className="pl-4 space-y-1">
                                    <Link to="/banking" className="block pt-4 hover:text-red-400" onClick={toggleMenu}>
                                        {t("nav.payment.banking")}
                                    </Link>
                                    <Link to="/ecommerce/payment" className="block pt-4 hover:text-red-400" onClick={toggleMenu}>
                                        {t("nav.online.payment")}
                                    </Link>
                                    <Link to="/otc" className="block pt-4 hover:text-red-400" onClick={toggleMenu}>
                                        {t("nav.otc")}
                                    </Link>
                                    {/* <Link to="/otc" className="block pt-4 hover:text-red-400" onClick={() => { toggleMenu(); scrollToSection('usdt') }}>
                                        {t("nav.usdt")}
                                    </Link> */}
                                </div>
                            )}
                        </div>

                        {/* online accounts */}
                        <div className="px-4 py-2">
                            <button
                                className={`w-full hover:text-red-400 ${activeMenu === "online" ? 'text-red-400' : ''}`}
                                onClick={() => toggleSubMenu("online")}
                            >
                                {t("nav.online")}
                            </button>
                            {activeMenu === "online" && (
                                <div className="pl-4 space-y-1">

                                    <Link to="/ecommerce/account" className="block pt-4 hover:text-red-400" onClick={toggleMenu}>
                                        {t("nav.online.account")}
                                    </Link>
                                    <Link to="/ecommerce/financial" className="block hover:text-red-400" onClick={toggleMenu}>
                                        {t("nav.online.financial")}
                                    </Link>
                                </div>
                            )}
                        </div>


                        {/* other services */}
                        <div className="px-4 py-2">
                            <button
                                className={`w-full hover:text-red-400 ${activeMenu === "other" ? 'text-red-400' : ''}`}
                                onClick={() => toggleSubMenu("other")}
                            >
                                {t("nav.other")}
                            </button>
                            {activeMenu === "other" && (
                                <div className="pl-4 space-y-1">
                                    <Link to="/insurance" className="block pt-4 hover:text-red-400" onClick={toggleMenu}>
                                        {t("nav.insurance")}
                                    </Link>
                                    <Link to="/vpn" className="block hover:text-red-400" onClick={toggleMenu}>
                                        {t("nav.vpn")}
                                    </Link>
                                </div>
                            )}
                        </div>

                        {/* contact */}
                        <Link to="/contact" className="block pt-4 hover:text-red-400" onClick={toggleMenu}>
                            {t("nav.contact")}
                        </Link>

                        <div className="px-4 py-8 w-full flex justify-end">
                            <LanguageSwitcher />
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
}
