import React, { createContext, useContext, useState } from 'react';

type Language = 'zh' | 'en';

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
  t: (key: string) => string;
}

const translations = {
  zh: {
    'nav.Company': '美国公司',
    'nav.Company.us.registration':'美国公司注册',
    'nav.Company.us.maintenance':'美国公司税务',
    'nav.Company.out.registration':'北美公司注册',
    'nav.offshore': '离岸公司注册',
    'nav.oversea':'海外公司',
    'nav.payment.banking': '银行开户',
    'nav.payment': '支付通道',
    "nav.settlement": '承兑服务',
    "nav.otc": 'OTC牌照',
    'nav.msb': 'MSB牌照',
    "nav.usdt": 'USDT承兑',
    'nav.news': '最新资讯',
    'nav.online':'电商金融',
    'nav.online.payment':'网络支付',
    'nav.online.account':'电商账户',
    'nav.online.financial':'金融账户',
    "nav.notarization":'公证服务',
    'nav.other':'其他业务',
    'nav.insurance': '地产保险服务',
    'nav.vpn': 'VPN服务',
    'nav.contact': '联系我们',
    'hero.title': '您的海外商业成功之门',
    'hero.subtitle': '专业的离岸公司注册、国际银行解决方案和全方位商业服务，为全球企业家保驾护航。',
    'hero.cta': '立即开始',
    'services.title': '我们的服务',
    'services.company.title': '美国公司注册',
    'services.company.description': '提供完整的美国公司注册服务和持续合规支持',
    'services.banking.title': '国际银行开户',
    'services.banking.description': '主要美国和国际银行的远程开户服务',
    'services.offshore.title': '离岸解决方案',
    'services.offshore.description': '战略性离岸公司架构和资产保护',
    'services.financial.title': '金融账号开通',
    'services.financial.description': '支持股票投资和加密货币交易账号开通',
    'services.vpn.title': 'VPN服务',
    'services.vpn.description': 'VPN设备安装和维护',
    'whyChooseUs.title': '为什么选择我们',
    'whyChooseUs.years': '年经验',
    'whyChooseUs.companies': '家公司注册',
    'whyChooseUs.satisfaction': '客户满意度',
    'contact.title': '准备开启您的美国商业之旅？',
    'contact.subtitle': '我们的专家将为您提供全程指导',
    'contact.cta': '预约咨询'
  },
  en: {
    'nav.Company': 'US Company',
    'nav.Company.us.registration':'US Company Registration',
    'nav.Company.us.maintenance':'US Company Maintenance',
    'nav.Company.out.registration':'Other Country Company Registration',
    'nav.offshore': 'Offshore Services',
    'nav.oversea': 'Oversea Company',
    'nav.payment':'Payment Method',
    'nav.payment.banking': 'Banking',
    'nav.settlement': 'Settlement Services',
    "nav.notarization":'Notarization Services',
    'nav.otc': 'OTC License',
    'nav.msb': 'MSB License',
    'nav.usdt':'USDT Service',
    'nav.news': 'News',
    'nav.online':'E-commerce',
    'nav.online.payment':'E-commerce Payments',
    'nav.online.account':'E-commerce Accounts',
    'nav.online.financial':'Financial Accounts',
    'nav.other':'Other Services',
    'nav.insurance': 'Insurance Services',
    'nav.vpn':'VPN Services',
    'nav.contact': 'Contact',
    'hero.title': 'Your Gateway to American Business Success',
    'hero.subtitle': 'Professional offshore company registration, international banking solutions, and comprehensive business services for global entrepreneurs.',
    'hero.cta': 'Get Started Today',
    'services.title': 'Our Services',
    'services.company.title': 'US Company Formation',
    'services.company.description': 'Complete US company registration services with ongoing compliance support',
    'services.banking.title': 'International Banking',
    'services.banking.description': 'Remote bank account opening with major US and international banks',
    'services.offshore.title': 'Offshore Solutions',
    'services.offshore.description': 'Strategic offshore company structures and asset protection',
    'services.financial.title': 'Financial Accounts',
    'services.financial.description': 'Support for stock investments and cryptocurrency transactions',
    'services.vpn.title': 'VPN Services',
    'services.vpn.description': 'VPN device installation and maintenance',
    'whyChooseUs.title': 'Why Choose Us',
    'whyChooseUs.years': 'Years Experience',
    'whyChooseUs.companies': 'Companies Registered',
    'whyChooseUs.satisfaction': 'Client Satisfaction',
    'contact.title': 'Ready to Start Your American Business Journey?',
    'contact.subtitle': 'Our experts are here to guide you through every step',
    'contact.cta': 'Schedule a Consultation'
  }
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState<Language>('zh');

  const t = (key: string): string => {
    return translations[language][key as keyof typeof translations['en']] || key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}