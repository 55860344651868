import { Building2, Landmark, Globe2, FileCheck, Printer  } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';
import { Link } from 'react-router-dom';

export default function Services() {
  const { t } = useLanguage();

  const services = [
    {
      icon: Building2,
      link: '/company',
      titleKey: 'services.company.title',
      descriptionKey: 'services.company.description'
    },
    {
      icon: Landmark,
      link: '/banking',
      titleKey: 'services.banking.title',
      descriptionKey: 'services.banking.description'
    },
    {
      icon: Globe2,
      link: '/offshore',
      titleKey: 'services.offshore.title',
      descriptionKey: 'services.offshore.description'
    },
    {
      icon: FileCheck,
      link: '/ecommerce/financial',
      titleKey: 'services.financial.title',
      descriptionKey: 'services.financial.description'
    },
    {
      icon: Printer,
      link: '/vpn',
      titleKey: 'services.vpn.title',
      descriptionKey: 'services.vpn.description'
    }
  ];

  return (
    <div className="py-20 bg-gray-50">
      <div className="max-w-8xl mx-auto px-16 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-12">{t('services.title')}</h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-8">
          {services.map((service, index) => (
            <Link to={service.link} key={index} className="card bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
              <service.icon className="h-12 w-12 text-blue-900 mb-4" />
              <h3 className="text-xl font-semibold mb-2">{t(service.titleKey)}</h3>
              <p className="text-gray-600">{t(service.descriptionKey)}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}