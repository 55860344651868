export default function BOIPage() {
    return (
        <div className="min-h-screen bg-gray-50">
            {/* Hero Section */}
            <div className="bg-blue-900 text-white py-24 pt-32">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-bold mb-4">BOI申报</h1>
                    <p className="text-xl">向美国金融执法犯罪局申报公司实际控制人信息。</p>
                    <p className="text-xl pt-16">2024年1月1号之前成立的公司, 需要在2024年12月31号之前完成申报。</p>
                    <p className="text-xl pt-4">之后成立的公司需要在公司成立之日起的90天内完成申报。</p>
                </div>
            </div>
            {/* Requirements Section */}
            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-gray-900 mb-8">所需资料清单:</h2>
                    <div className="bg-gray-50 rounded-lg p-6">
                        <div className="space-y-4">
                            <p className="text-gray-800">
                                1. 公司名字, 地址。
                            </p>
                            <p className="text-gray-800">
                                2. EIN号码。
                            </p>
                            <p className="text-gray-800">
                                3. 实际控制人护照扫描件 (需要上传护照)。
                            </p>
                            <p className="text-gray-800">
                                4. 实际控制人居住地址 (如:身份证/驾照上的地址)。
                            </p>
                            <p className="text-gray-800">
                                5. Email邮箱。
                            </p>
                        </div>
                        <p className="text-gray-500 text-md mt-16">
                            *实际控制人: 股份达到或超过25%。
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}