export default function NotarizationPage() {
    const Apostille_Docs = [
        '出生证明',
        '结婚证',
        '护照',
        '学历/成绩单',
        '委托书/申明书',
        '合同',
        '营业执照/注册证书',
        '...'
    ];
    const Notarization_Docs = [
        '营业执照/注册证书 (Articles of Incorporation)',
        '公司年报 (Annual/Biennial Report)',
        '良好存续证明 (Certificate of Good Standing)',
        '公司章程 (Bylaws)',
        '会议纪要 (Minutes of Meeting)',
        '股票证书 (Stock Certificate)',
        '在职证明 (Incumbency)',
        '...'
    ];
    return (
        <div className="min-h-screen bg-gray-50">
            {/* Hero Section */}
            <div className="bg-blue-900 text-white py-16 pt-32">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-12">
                    <h1 className="text-4xl font-bold mb-4">公证认证</h1>
                    <p className="text-xl text-blue-200">
                        个人/商业文件公证 (Notarization), 海牙认证(Apostille), 大使馆/领事馆认证.
                    </p>
                </div>
            </div>
            {/* Notarization flex */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-4">
                    <div className="card bg-white p-16 rounded-lg shadow-md">
                        <div className="flex flex-col lg:flex-row items-left justify-between mb-4 mr-8">
                            <div>
                                <h2 className="text-xl font-bold mb-2">美国海牙认证 (apostille)</h2>
                                <p className="text-gray-600 mb-4">可用于海牙认证的文件(原件或扫描件):</p>
                            </div>
                            <span className="text-2xl font-bold text-red-600">$350</span>
                        </div>
                        <p className="text-gray-600">
                            {Apostille_Docs.map((doc, index) => (
                                <li key={index}>{doc}</li>
                            ))}
                        </p>
                    </div>
                    <div className="card bg-white p-16 rounded-lg shadow-md">
                        <div className="flex flex-col lg:flex-row items-left justify-between mb-4 mr-8">
                            <div>
                                <h2 className="text-xl font-bold mb-2">三级认证 (中国驻美国领事馆 认证)</h2>
                                <p className="text-gray-600 mb-4">可用于三级认证的文件(原件或扫描件):</p>
                            </div>
                            <span className="text-2xl font-bold text-red-600">$500</span>
                        </div>
                        <p className="text-gray-600">
                            {Notarization_Docs.map((doc, index) => (
                                <li key={index}>{doc}</li>
                            ))}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}