import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LanguageProvider } from './context/LanguageContext';
import Navbar from './components/layout/Navbar';
import Hero from './components/MainPage/Hero';
import Services from './components/MainPage/Services';
import WhyChooseUs from './components/MainPage/WhyChooseUs';
import ContactCTA from './components/MainPage/ContactCTA';
import CompanyPage from './components/Company/CompanyPage';
import BankingPage from './components/Payment/BankingPage';
import OffshorePage from './components/OffShore/OffshorePage';
import NewsPage from './extraPage/NewsPage';
import ContactPage from './components/ContactPage';
import EPaymentPage from './components/Payment/EPaymentPage';
import EAccountPage from './components/ECommerce/EAccountPage';
import ScrollToTop from './components/utils/ScrollToTop';
import OTCPage from './components/Settlement/OTCPage';
import BOIPage from './components/Company/BOIPage';
import NotarizationPage from './extraPage/NotarizationPage';
import CanadaOffShore from './components/OffShore/CanadaOffShore';
import VPNPage from './components/OtherService/VPNPage';
import InsurancePage from './components/OtherService/InsurancePage';
import NavbarMobile from './components/layout/NavbarMobile';
import FinancialAccountPage from './components/ECommerce/FinancialAccountPage';
import SampleCharge from './Authorize/sampleCharge';
import StripeCharge from './Stripe/StripeCharge';
import PaymentSuccess from './Stripe/PaymentSuccess';
import PaymentCancel from './Stripe/PaymentCancel';
function App() {
  return (
    <LanguageProvider>
      <Router>
        <div className="min-h-screen bg-white">
          {/* Show Navbar on large screens */}
          <div className="hidden lg:block">
            <Navbar />
          </div>
          {/* Show NavbarMobile on small screens */}
          <div className="block lg:hidden">
            <NavbarMobile />
          </div>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={
              <>
                <Hero />
                <Services />
                <WhyChooseUs />
                <ContactCTA />
              </>
            } />
            <Route path="/company" element={<CompanyPage />} />
            <Route path="/banking" element={<BankingPage />} />
            <Route path="/offshore" element={<OffshorePage />} />
            <Route path="/ecommerce/payment" element={<EPaymentPage />} />
            <Route path="/ecommerce/account" element={<EAccountPage />} />
            <Route path="/ecommerce/financial" element={<FinancialAccountPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/otc" element={<OTCPage />} />
            <Route path="/boi" element={<BOIPage />} />
            <Route path="/notarization" element={<NotarizationPage />} />
            <Route path="/offshore/Canada" element={<CanadaOffShore />} />
            <Route path="/vpn" element={<VPNPage />} />
            <Route path="/insurance" element={<InsurancePage />} />
            <Route path="/charge" element={<SampleCharge />} />
            <Route path="/stripe" element={<StripeCharge />} />
            <Route path="/payment/success" element={<PaymentSuccess />} />
            <Route path="/payment/cancel" element={<PaymentCancel />} />
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;