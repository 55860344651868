
import { US, HK } from 'country-flag-icons/react/3x2'
import 'react-social-icons/tiktok'
import ContactUsWedge from '../utils/ContactUsWedge';
import { services } from '@/components/Data/services';
import CompanyCard from '../Company/ProductCard';

const InsuranceFlage =[
    {name:"美国保险", flag:US},
    {name:"香港保险", flag:HK} ,
    {name:"美国物业管理", flag:US},
    {name:"美国地产开发", flag:US},
]

// get services
const InsuranceService = services.filter((service: { name: string; }) => service.name == "Insurance");
const PropertyService = services.filter((service: { name: string; }) => service.name == "Property");

//get products
const InsuranceServiceProducts = InsuranceService[0].products;
const PropertyServiceProducts = PropertyService[0].products;

export default function InsurancePage() {
    return (
        <div className="min-h-screen bg-gray-50">
            <div className="bg-blue-900 text-white py-16 pt-32">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-bold mb-4">地产与保险</h1>
                </div>
            </div>

            {/* Insurance Section */}
            <section className="py-16 bg-gray-100">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-gray-900 mb-2">保险业务</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-24 pt-12 pb-16 px-16 max-w-7xl justify-center mx-auto ">
                    {InsuranceServiceProducts.map((product: { product: string; price: number, minDay: string; maxDay: string; }) => {
                            const details = InsuranceFlage.find((flag)=> flag.name == product.product);
                            return (
                                <CompanyCard details={details} product={product} service="Insurance 保险业务" />
                            );
                        })}
                    </div>
                </div>
            </section>

            {/* Property Management Section */}
            <section className="py-16 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-3xl font-bold text-gray-900 mb-12">美国地产业务</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-24 pt-12 pb-16 px-16 max-w-7xl justify-center mx-auto ">
                    {PropertyServiceProducts.map((product: { product: string; price: number, minDay: string; maxDay: string; }) => {
                            const details =InsuranceFlage.find((flag)=> flag.name == product.product);
                            return (
                                <CompanyCard details={details} product={product} service="Property 美国地产业务" />
                            );
                        })}
                    </div>
                </div>
            </section>
            <ContactUsWedge />
        </div>
    );
}