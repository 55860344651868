import { Link } from "react-router-dom";
import { useState } from "react";
export default function ProductCard(props: { details: any, product: any, service: any }) {
  const details = props.details;
  const product = props.product;
  const service = props.service;
  const handleClick = (type: string, code: string) => {
    // Your click logic here
    alert(`${type} in ${code} is coming soon!`);
    console.log("Button clicked!")
    console.log(type, code);
  };

  return (
    <div key={details.code+service} className="card bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="p-6">
        <div className="flex flex-grow items-top justify-between mb-4 max-h-32">
          <div className="flex  flex-col items-left justify-between mb-4">
            <div className="flex items-center justify-between">
              {details.flag && <details.flag className="mr-2 h-8 w-8" />}
              <h3 className="text-2xl font-semibold">{product.product}</h3>
            </div>
            <p className="text-xl text-gray-600 mt-4">{details.nameEn}</p>
            <p className="text-gray-600 my-2 max-w-64">{details.description}</p>
          </div>
          <div className="flex flex-col items-center justify-stretch mb-4">
            {product.price > 0 && <span className="text-2xl font-bold text-red-600">${product.price}{product.exactPrice ? "" : "起"}</span>}
            {product.minDay && <span className="text-xl text-gray-600 mt-4 w-2/3 lg:w-full">
              注册时间约 {product.minDay}-{product.maxDay} 个工作日
            </span>}
          </div>
        </div>
        <div className="h-12 flex gap-4 items-center justify-between mb-4">
          {details.learnMore && (
            <Link to={`/offshore/${details.nameEn}`} className="bg-blue-600 w-full w-1/2 text-white text-center py-2 rounded-lg hover:bg-blue-700 transition-colors">
              了解详情  &raquo;
            </Link>
          )}
         
          {product.price >0 && product.exactPrice&&(
            <Link
              to={`/stripe`}
              state={{ product: product.product, service: service, price: product.price }}
              className="w-full bg-blue-600 text-white text-center py-2 rounded-lg hover:bg-blue-700 transition-colors">
              立即注册
            </Link>
          )}
          {product.price <= 0 || !product.exactPrice && (
             <button onClick={() => handleClick(service, product.product)} className="w-full bg-blue-600 text-white text-center py-2 rounded-lg hover:bg-blue-700 transition-colors">
             立即咨询
           </button>
          )}
        </div>
      </div>
    </div>
  );
}