import { Link } from "react-router-dom";
import { Mail } from "lucide-react";

export default function ContactButton() {
    return (
        <div className="text-center">
            <Link to="/contact" className="bg-blue-800 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-red-700 transition-colors inline-flex items-center"
                onClick={() => {
                    setTimeout(() => {
                        const element = document.getElementById("contact-form");
                        if (element) {
                            element.scrollIntoView({ behavior: "smooth", block: "start" });
                        }
                    }, 200); // Delay to ensure the new page has loaded
                }}>
                <Mail className="mr-2 h-5 w-5" />
                立即咨询
            </Link>
        </div>
    );
}