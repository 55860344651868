import { useLocation } from 'react-router-dom';
import { useState } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

type BillingAddress = {
    name: string;
    email: string;
    phone: string;
}

export default function StripeCharge() {
    const location = useLocation();
    const { product, service, price } = location.state || { product: "empty product", service: "empty service", price: 0 }
    const [processing,setProcessing] = useState<boolean>(false);
    const [billingAddress, setBillingAddress] = useState<BillingAddress>({
        name: '',
        email: '',
        phone: '',
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const env = import.meta.env;
    const serverUrl = env.VITE_SERVER_URL;



    const validate = () => {
        let newErrors: { [key: string]: string } = {};
        if (!billingAddress.name.trim()) newErrors.name = "Name 姓名 is required";
        if (!billingAddress.email.trim() || !/\S+@\S+\.\S+/.test(billingAddress.email)) {
            newErrors.email = "Valid email 有效邮箱 is required";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const validateField =(fieldName: keyof BillingAddress)=>{
        let newErrors: { [key: string]: string } = {};

        if(!billingAddress[fieldName].trim()){
            newErrors[fieldName] = `${fieldName} is required`;
            setErrors(newErrors);
        }else{
            delete newErrors[fieldName];
            setErrors(newErrors);
        }

        //validate email
        if(fieldName ==='email' && !/\S+@\S+\.\S+/.test(billingAddress.email)){
            newErrors[fieldName] = `Valid email is required`;
            setErrors(newErrors);
        }else if(fieldName ==='email' && /\S+@\S+\.\S+/.test(billingAddress.email)){
            delete newErrors[fieldName];
            setErrors(newErrors);
        }
        
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setProcessing(true);
        if (!validate()) return;
        const dataJson =JSON.stringify({
            "product": product,
            "service": service,
            "price": price,
            "billingAddress": billingAddress,
        })

        try {
            const response = await fetch(`${serverUrl}/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: dataJson,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            
            if (data.url) {
                setProcessing(false)
                window.location.href = data.url; // Redirect user to Stripe Checkout
            }
        } catch (error) {
            console.error('Error posting form:', error);
            setProcessing(false)
        }

    }
    return (
        <div className="min-h-screen bg-gray-50  ">
            <div className="flex flex-col items-center justify-center  pt-32">

                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl mb-8">
                        <h2 className="text-xl mb-2 text-center">Product Information</h2>
                        <p className="text-lg text-center text-gray-600 mb-8"> 请确认商品信息</p>
                        <div className="flex justify-between border-b px-4 mb-2">
                            <div className="mt-1 p-2 w-full w-1/3 text-left" >Product 产品</div>
                            <div className="mt-1 p-2 w-full w-2/3 text-right" >{product}</div>
                        </div>
                        <div className="flex justify-between border-b  px-4 mb-2">
                            <div className="mt-1 p-2 w-full w-1/3 text-left" >Service 服务</div>
                            <div className="mt-1 p-2 w-full w-2/3 text-right" >{service}</div>
                        </div>
                        <div className="flex justify-between border-b px-4 mb-2">
                            <div className="mt-1 p-2 w-full w-1/3 text-left" >Price 价格</div>
                            <div className="mt-1 p-2 w-full w-2/3 text-right" >${price}</div>
                        </div>
                    </div>

                    <div>
                        <h2 className="text-xl mb-2 text-center">Customer Information</h2>
                        <p className="text-lg text-center text-gray-600 mb-4"> 请填写顾客信息</p>
                        <form id="paymentForm"
                            onSubmit={handleSubmit}
                        >
                            <input type="hidden" name="product" value={product} />
                            <input type="hidden" name="service" value={service} />
                            <input type="hidden" name="price" value={price} />

                            <label className="block text-sm font-medium text-gray-700"> Name 姓名</label>
                            <input type="text" name="name" value={billingAddress.name} className="mt-1 p-2 w-full border rounded-md" onChange={handleChange} onKeyUp={() => { validateField('name') }} />
                            {errors.name && <p className="text-red-500 text-xs">{errors.name}</p>}

                            <label className="block text-sm font-medium text-gray-700">Phone Number 电话</label>
                            <PhoneInput
                                className="mt-1 p-2 w-full border rounded-md"
                                name="phone"
                                value={billingAddress.phone}
                                defaultCountry="us"
                                onChange={(phone) => { setBillingAddress({ ...billingAddress, phone: phone }) }}
                            />

                            <label className="block text-sm font-medium text-gray-700">Email 邮箱</label>
                            <input type="email" name="email" value={billingAddress.email} className="mt-1 p-2 w-full border rounded-md" onChange={handleChange} onKeyUp={() => { validateField('email') }} />
                            {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}

                            {processing ? <button
                                type="submit"
                                className="disabled mt-4 w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                            >
                                正在处理...
                            </button>
                                : <button
                                    type="submit"
                                    className="mt-4 w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                >
                                前往付款
                            </button>}

                        </form>
                    </div>
                </div>

            </div>

        </div>

    )
}