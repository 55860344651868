import { Mail } from 'lucide-react';
import { Link } from 'react-router-dom';

const ContactUsWedge = () => {
  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-blue-900 rounded-2xl text-white p-8 md:p-12">
          <h2 className="text-3xl font-bold mb-8 text-center">联系方式</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="text-2xl mb-2">Telegram</div>
              <div className="text-xl text-blue-300">OverseaBiz</div>
            </div>
            <div className="text-center">
              <div className="text-2xl mb-2">微信</div>
              <div className="text-xl text-blue-300">OverseaBiz</div>
            </div>
            <div className="text-center">
              <div className="text-2xl mb-2">WhatsApp</div>
              <div className="text-xl text-blue-300">+1 (424) 245-0624</div>
            </div>
          </div>
          <div className="mt-8 text-center">
            <Link to="/contact" className="bg-red-600 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-red-700 transition-colors inline-flex items-center">
              <Mail className="mr-2 h-5 w-5" />
              立即咨询
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsWedge;